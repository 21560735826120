@use '../../styles/partials/variables';


a {
    text-decoration: none;
    color: #fff;
}

.projects {
    &__header {
        &-title {}

        &-underline {
            border: 0;
            border-top: 2px solid #09f;
            height: 1px;
            margin-top: 4px;
            width: 30px;
        }

    }

}

.gradients {
    @include variables.mobile {
        display: flex;
        margin: 0;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%), 0 1px 6px rgb(65 63 63 / 25%);
        /* margin-bottom: 1.5rem; */
        max-width: 100%;
        background: linear-gradient(rgba(6, 112, 252, 0.7), #422efd);
    }

    @include variables.desktop {
        display: flex;
        justify-content: center;
        margin-left: 10rem;
        margin-right: 10rem;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%),
            0 1px 6px rgb(65 63 63 / 25%);
        margin-bottom: 1.5rem;
        max-width: 100%;
        background: linear-gradient(rgba(6, 112, 252, .7), #422efd);
    }

    @include variables.bigDeskTop {
        display: flex;
        justify-content: center;
        margin-left: 10rem;
        margin-right: 10rem;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%),
            0 1px 6px rgb(65 63 63 / 25%);
        margin-bottom: 1.5rem;
        max-width: 100%;
        background: linear-gradient(rgba(6, 112, 252, .7), #422efd);

    }

    @include variables.bigBoyDeskTop {
        margin-left: 25rem;
        margin-right: 25rem;

    }
}

.gradients__two {
    @include variables.mobile {
        display: flex;
        margin: 0;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%), 0 1px 6px rgb(65 63 63 / 25%);
        /* margin-bottom: 1.5rem; */
        max-width: 100%;
        background: linear-gradient(rgba(6, 112, 252, 0.7), #ef93ec);
    }

    @include variables.desktop {
        display: flex;
        justify-content: center;
        margin-left: 10rem;
        margin-right: 10rem;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%),
            0 1px 6px rgb(65 63 63 / 25%);
        margin-bottom: 1.5rem;
        max-width: 100%;
        background: linear-gradient(rgba(6, 112, 252, .7), #ef93ec);
    }

    @include variables.bigDeskTop {
        display: flex;
        justify-content: center;
        margin-left: 10rem;
        margin-right: 10rem;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%),
            0 1px 6px rgb(65 63 63 / 25%);
        margin-bottom: 1.5rem;
        max-width: 100%;
        background: linear-gradient(rgba(6, 112, 252, .7), #ef93ec);
    }

    @include variables.bigBoyDeskTop {
        margin-left: 25rem;
        margin-right: 25rem;

    }
}

.gradients__three {
    @include variables.mobile {
        display: flex;
        margin: 0;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%), 0 1px 6px rgb(65 63 63 / 25%);
        /* margin-bottom: 1.5rem; */
        max-width: 100%;
        background: linear-gradient(#ef93ec, rgba(6, 112, 252, .7));
    }

    @include variables.desktop {
        display: flex;
        justify-content: center;
        margin-left: 10rem;
        margin-right: 10rem;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%),
            0 1px 6px rgb(65 63 63 / 25%);
        margin-bottom: 1.5rem;
        max-width: 100%;
        background: linear-gradient(#ef93ec, #457b9d);
    }

    @include variables.bigDeskTop {
        display: flex;
        justify-content: center;
        margin-left: 10rem;
        margin-right: 10rem;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%),
            0 1px 6px rgb(65 63 63 / 25%);
        margin-bottom: 1.5rem;
        max-width: 100%;
        background: linear-gradient(#ef93ec, #457b9d);

    }

    @include variables.bigBoyDeskTop {
        margin-left: 25rem;
        margin-right: 25rem;

    }
}

.gradients__four {
    @include variables.mobile {
        display: flex;
        margin: 0;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%), 0 1px 6px rgb(65 63 63 / 25%);
        /* margin-bottom: 1.5rem; */
        max-width: 100%;
        background: linear-gradient(#457b9d, #2a9d8f);
    }

    @include variables.desktop {
        display: flex;
        justify-content: center;
        margin-left: 10rem;
        margin-right: 10rem;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%),
            0 1px 6px rgb(65 63 63 / 25%);
        margin-bottom: 1.5rem;
        max-width: 100%;
        background: linear-gradient(#457b9d, #2a9d8f);
    }

    @include variables.bigDeskTop {
        display: flex;
        justify-content: center;
        margin-left: 10rem;
        margin-right: 10rem;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%),
            0 1px 6px rgb(65 63 63 / 25%);
        margin-bottom: 1.5rem;
        max-width: 100%;
        background: linear-gradient(#457b9d, #2a9d8f);

    }

    @include variables.bigBoyDeskTop {
        margin-left: 25rem;
        margin-right: 25rem;

    }
}

.gradients__five {
    @include variables.mobile {
        display: flex;
        margin: 0;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%), 0 1px 6px rgb(65 63 63 / 25%);
        /* margin-bottom: 1.5rem; */
        max-width: 100%;
        background: linear-gradient(#2a9d8f, #f4acb7);
    }

    @include variables.desktop {
        display: flex;
        justify-content: center;
        margin-left: 10rem;
        margin-right: 10rem;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%),
            0 1px 6px rgb(65 63 63 / 25%);
        margin-bottom: 1.5rem;
        max-width: 100%;
        background: linear-gradient(#2a9d8f, #f4acb7);
    }

    @include variables.bigDeskTop {
        display: flex;
        justify-content: center;
        margin-left: 10rem;
        margin-right: 10rem;
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%),
            0 1px 6px rgb(65 63 63 / 25%);
        margin-bottom: 1.5rem;
        max-width: 100%;
        background: linear-gradient(#2a9d8f, #f4acb7);

    }

    @include variables.bigBoyDeskTop {
        margin-left: 25rem;
        margin-right: 25rem;

    }
}




.project {
    @include variables.mobile {
        color: #fff;
        padding: 39.45rem -6.125rem;
        text-shadow: 0 0.4px 0.4px rgb(0 0 0 / 15%);
        width: 100%;
        display: grid;
        grid-gap: 1.6219999999999999rem;
        margin: 0;
        grid-template-columns: 2fr;
        padding: 0;
        max-width: 1000px;
        justify-items: center;
    }

    @include variables.desktop {
        color: #fff;
        padding: 3.45rem 1.875rem;
        text-shadow: 0 0.4px 0.4px rgb(0 0 0 / 15%);
        width: 100%;
        -webkit-box-align: center;
        align-items: center;
        display: grid;
        grid-gap: 2.622rem;
        grid-template-columns: 1fr 1fr;
        margin: 70px;
        max-width: 1000px;

    }

    &__info {
        @include variables.mobile {
            display: flex;
            width: 80%;
            padding: 1rem;
            flex-direction: column;
        }

        @include variables.desktop {
            display: flex;
            flex-direction: column;
        }

        &--title {
            font-family: font-medium;
            font-size: 1.4238rem;
            line-height: 1.875rem;
            margin-bottom: 0.375rem;
        }

        &--description {
            font-size: 1rem;
            line-height: 1.5rem;
        }

        &--link {}

        &--list {
            grid-template-columns: repeat(5, auto);
            list-style: none;
            display: grid;
            grid-row-gap: 0.1875rem;
            padding: 0;


            &-item::before {
                border-radius: 3px;
                content: "";
                display: inline-block;
                height: 10px;
                margin-right: 0.375rem;
                width: 10px;
                background-color: #16d97e;
            }

            &-item-yellow::before {
                border-radius: 3px;
                content: "";
                display: inline-block;
                height: 10px;
                margin-right: 0.375rem;
                width: 10px;
                background-color: #fce631;
            }

            &-item-red::before {
                border-radius: 3px;
                content: "";
                display: inline-block;
                height: 10px;
                margin-right: 0.375rem;
                width: 10px;
                background-color: #7209b7;
            }

            &-item-blue::before {
                border-radius: 3px;
                content: "";
                display: inline-block;
                height: 10px;
                margin-right: 0.375rem;
                width: 10px;
                background-color: blue;
            }

            &-item-pink::before {
                border-radius: 3px;
                content: "";
                display: inline-block;
                height: 10px;
                margin-right: 0.375rem;
                width: 10px;
                background-color: #f4acb7;

            }
        }
    }
}

.table_top-container {
    text-decoration: none;
    color: #fff;

    @include variables.mobile {
        margin-top: 1rem;
    }
}

.project__img {
    @include variables.mobile {
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%), 0 1px 6px rgb(65 63 63 / 25%);
        margin-bottom: 1.122rem;
        /* max-width: 503px; */
        width: 90%;
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        height: 30vh;
    }

    @include variables.desktop {
        border-radius: 3px;
        box-shadow: 0 3px 4px rgb(37 37 37 / 20%),
            0 1px 6px rgb(65 63 63 / 25%);
        margin-bottom: 1.122rem;
        max-width: 500px;
        transition: all .3s cubic-bezier(.25, .8, .25, 1);
        height: 30vh;
    }

}

.animate {
    animation: animate 1s ease 0s 1 normal forwards;
}

@keyframes animate {

    0% {
        opacity: 0;
        transform: translateX(-250px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}
$mobile-breakpoint: 320px;
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;
$bigDeskTop-breakpoint: 1980px;
$biggestdesktop-breakpoint: 2560px;

@mixin mobile {
    @media screen and (min-width: $mobile-breakpoint) {
        @content;
    }

}

@mixin tablet {
    @media screen and (min-width:$tablet-breakpoint) {
        @content;
    }
}

@mixin desktop {
    @media screen and (min-width:$desktop-breakpoint) {
        @content;

    }

}

@mixin bigDeskTop {
    @media screen and (min-width:$bigDeskTop-breakpoint) {
        @content;

    }
}

@mixin bigBoyDeskTop {
    @media screen and (min-width:$biggestdesktop-breakpoint) {
        @content;

    }
}
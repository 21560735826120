@use '../../styles/partials/variables';



.header {
    @include variables.mobile {
        margin: auto 0;
        padding: 40px 0;
        display: flex;
        text-align: center;
        width: 90;
        position: relative;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    @include variables.desktop {

        margin: auto 0;
        padding: 40px 0;
        display: flex;
        text-align: center;
        width: 100%;
        position: relative;
        flex-direction: column;
        align-items: center;
        padding: 0;
    }

    &__nav {
        @include variables.mobile {
            text-decoration: none;
            width: 100%;
            padding-top: 0;
        }

        @include variables.desktop {

            padding: 5px 0;
            text-decoration: none;
            width: 100%;
            padding-top: 0;
        }


        &-list {
            @include variables.mobile {
                margin-top: 0;
                padding-left: 0;
                display: flex;
                list-style: none;
                flex-direction: row;
                gap: 1rem;
                justify-content: center;
                text-decoration: none;
                margin-bottom: 0;
            }

            @include variables.desktop {
                margin-top: 0;
                padding-left: 0;
                display: flex;
                list-style: none;
                flex-direction: row;
                gap: 2rem;
                justify-content: center;
                text-decoration: none;
                margin-bottom: 0;
            }
        }

    }

}

.item-hover:hover {
    color: #00F5D4;

}

.link-remove {
    text-decoration: none;
    font-size: 1rem;
    line-height: 1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: #fff;
}

.myName {
    color: #fff;
    font-family: font-medium;
    font-size: 1.6018rem;
    line-height: 2.25rem;
    margin: 30px 0;
    text-align: center;
    text-shadow: 0 1px 1px rgb(0 0 0 / 15%);
}

.waves {
    @include variables.mobile {
        position: absolute;
        right: 0;
        top: 0;
        width: 252vw;
        z-index: -1;
    }

    @include variables.desktop {
        position: absolute;
        right: 0;
        top: 0;
        width: 100vw;
        z-index: -1;
    }
}
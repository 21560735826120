@use '../../styles/partials/variables';

.card {
    @include variables.mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgb(0 0 0 / 10%), 0 1px 6px rgb(0 0 0 / 15%);
        max-width: 100%;
        padding: 3.45rem 1.5rem;
        position: relative;
        text-align: center;
        margin: 0;
    }

    @include variables.desktop {

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgb(0 0 0 / 10%),
            0 1px 6px rgb(0 0 0 / 15%);
        max-width: 100%;
        margin-left: 25rem;
        margin-right: 25rem;
        padding: 3.45rem 1.5rem;
        position: relative;
        text-align: center;

        &__title {
            color: #00b7ff;
            font-size: 1.2656rem;
            line-height: 1.875rem;
            margin-bottom: 1.875rem;

        }

        &__desc {}

        &__img {
            bottom: -15px;
            position: absolute;
            right: 15px;
            width: 150px;
        }
    }

    @include variables.bigDeskTop {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 1px 2px rgb(0 0 0 / 10%),
            0 1px 6px rgb(0 0 0 / 15%);
        max-width: 100%;
        margin-left: 35rem;
        margin-right: 35rem;
        padding: 3.45rem 1.5rem;
        position: relative;
        text-align: center;

        &__title {
            color: #00b7ff;
            font-size: 1.2656rem;
            line-height: 1.875rem;
            margin-bottom: 1.875rem;

        }

        &__desc {}

        &__img {
            bottom: -15px;
            position: absolute;
            right: 15px;
            width: 150px;
        }

    }

    @include variables.bigBoyDeskTop {
        margin-left: 50rem;
        margin-right: 50rem;

    }


}
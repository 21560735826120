@use '../../styles/partials/variables';

.skills {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.languages {
    @include variables.mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.databases {
    @include variables.mobile {
        display: flex;
        flex-direction: column;
        /* align-items: flex-start; */
        flex-wrap: wrap;
    }
}

.tools {
    @include variables.mobile {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }
}


.list {
    @include variables.mobile {
        display: flex;
        list-style: none;
        padding: 0;
        justify-content: center;
        gap: 1rem;
        flex-direction: row;
        align-items: center;
        align-content: center;
        flex-wrap: wrap;
    }

    @include variables.desktop {

        display: flex;
        list-style: none;
        gap: 1rem;
    }

}

.list__item::before {
    border-radius: 3px;
    content: "";
    display: inline-block;
    height: 10px;
    margin-right: 0.375rem;
    width: 10px;
}

.list_item--languages::before {
    background-color: #00b9ff;

}

.list_item--databases::before {
    background-color: #ffd728;


}

.list_item--tools::before {
    background-color: #16d97e;

}